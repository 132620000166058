<template>
  <router-view :key="$route.fullPath" />
</template>

<script>
export default {
  name: "Root",
  mounted() {
    const qToken = this.$route.query.token;
    const lToken = localStorage.getItem("token");

    const token = qToken || lToken;

    if (!token) {
      this.$router.push("/non-user");
      return;
    }

    this.$store.dispatch("GET_ASYNC_CATEGORY", { token });
  },
  created() {
    const isNewUser = this.$route.query.token;
    if (!isNewUser && this.$route.path === "/") {
      this.$router.push("/list");
    }
  },
};
</script>
